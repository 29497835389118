import React from "react"

import Card from "../shared_components/gatsby-card"
import Header from "../shared_components/gatsby-header"
import Seo from "../shared_components/seo"

import "@fortawesome/fontawesome-free/css/all.css"

const IndexPage = () => (
  <>
    <Seo />
    <Header size='10' style={{background: 'white', color: '#4D4D4D', fontWeight: 'bold'}}>
      Jason B. Cox
    </Header>
    <Header size='5' style={{fontWeight: 'bold'}}>
      Who am I?
    </Header>
    <Card link="https://twitter.com/jasonbcox0" image="/img/profile.png" imageStyle={{maxWidth: '200px', margin: '0 28px', borderRadius: '8px'}}>
      My name is Jason. Although many people simply know me as a software engineer, I’m also a serial hobbyist, avid tinkerer, and entrepreneur.
    </Card>
    <Card title="Github" link="https://github.com/jasonbcox" image="/img/Github.png" imageStyle={{maxWidth: '256px'}}>
<p>
Checkout my open source work.
</p>
    </Card>
    <Card title="LinkedIn Profile" link="https://www.linkedin.com/in/jasonbcox" image="/img/LinkedIn.png" imageStyle={{maxWidth: '256px'}}>
<p>
My experience is extremely varied, from building highly-scalable applications that serve millions of users per day to mission-critical software in aerospace.
</p>
    </Card>
    <Card oneliner={true}>
<div style={{display: 'flex'}}>
  <div style={{fontSize: '26px', fontWeight: 'bold'}}>PGP Fingerprint</div>
  <i class="fas fa-fingerprint" style={{fontSize: '32px', margin: '0 20px'}} />
  <div style={{fontSize: '26px'}}>3BB1 6D00 D9A6 D281 591B DC76 E448 6356 E7A8 1D2C</div>
</div>
    </Card>
  </>
)

export default IndexPage
